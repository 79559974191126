define(['$'], function($) {
  var normalisedDeviceType = $.trim($('html').data('platform').toLowerCase());
  var devicetype = normalisedDeviceType ? normalisedDeviceType.replace(/(^| )(\w)/g, function (match) { return match.toUpperCase(); }) : '';

  return {
      getDeviceType: function () {
          return devicetype;
      },
      getNormalisedDeviceType: function() {
        return normalisedDeviceType;
      }
  };
});
